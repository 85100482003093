import React, { useEffect } from 'react';
import "./Notification.css"

const Notification = (props) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            props.close();
        }, 2000); 
        return () => clearTimeout(timer);
    }, []); 

    return (
        <div className={`notification ${props.typeOfMessage==="info"?"info":"error"}`}>
            <button className="close-button" onClick={props.close}>X</button>
            {props.message}
        </div>
    );
}

export default Notification;
